<template>
  <div class="lefttotal">
    <touTop :headername="headerName" />
    <div class="shezhi">
      <div class="left">
        <sider
          :headername="headerName"
          :sideMenus="sideMenu"
          @transkey="getKey"
        />
      </div>
       <router-view/>
      <!-- <div class="right">
        <breadCrumb :siderContent="siderName" />
        <a-card :bordered="false" v-if="showContentId === 0">
          <div class="table-operator">
            <a-row>
              <a-col :span="6">
                <a-button type="primary" @click="handleAdd">新增</a-button>
              </a-col>
            </a-row>
          </div>
          <a-table
            ref="tabs"
            rowKey="printer_id"
            :loading="isLoading"
            :columns="columns"
            :data-source="loadData"
            @change="handleTableChange"  
            bordered
          >
            <span slot="action" slot-scope="text, item">
              <span
                style="
                  margin-right: 1vw;
                  background: rgba(255, 103, 0, 1);
                  width: 4vw;
                  height: 3.5vh;
                  display: inline-block;
                  line-height: 3.5vh;
                  text-align: center;
                  border-radius: 4px;
                  color: white;
                  border: rgba(255, 103, 0, 1) solid 1px;
                  cursor: pointer;
                "
                @click="handleEdit(item)"
                >编辑</span
              >
              <a
                style="
                  width: 4vw;
                  height: 3.5vh;
                  display: inline-block;
                  line-height: 3.5vh;
                  text-align: center;
                  border-radius: 4px;
                  color: rgba(80, 80, 80, 1);
                  border: rgba(214, 214, 214, 1) solid 1px;
                  cursor: pointer;
                "
                @click="handleDelete(item)"
                >删除</a
              >
            </span>
            <span slot="use" slot-scope="text, item">
              <div v-if="item.status === 0  || item.status === true" class="iconcss">
                <img :src="Img0" alt="" />
              </div>
              <div v-if="item.status === 1 ||  item.status === false" class="iconcss">
                <img :src="Img1" alt="" />
              </div>
            </span>
          </a-table>
          <AddForm
            ref="AddForm"
            @handleSubmit="handleRefresh"
            :zeng="showmodalzeng"
            @funcs="changemodal"
          />
          <EditForm
            ref="EditForm"
            @handleSubmit="handleRefresh"
            :everyList="everyListData"
            :bian="showmodalbian"
            @fun="changeModalBian"
          />
          <a-modal
            title="删除打印机"
            :visible="showDelete"
            @ok="handleOk"
            @cancel="handleCancel"
            ok-text="确认"
            cancel-text="取消"
          >
            <p>您确定要删除此项打印机记录吗</p>
          </a-modal>
        </a-card>
      </div> -->
    </div>
  </div>
</template>
<script>
////表格数据
import { shopStorey, tableList, list, deleted,toStore } from "../../request/api";
//import yingYe from '../../components/touTop/touTop.vue';
// import Img0 from "@/assets/keyong.svg";
// import Img1 from "@/assets/bukeyong.svg";
// import { mapState } from "vuex";
import touTop from "@/components/touTop/touTop";
// import AddForm from "./modules/AddForm";
// import EditForm from "./modules/EditForm";
import sider from "@/components/sider/sider";
// import merchant from "./modules/Merchant";
// import breadCrumb from "@/components/BreadCrumb/BreadCrumb";


export default {
  data() {
    return {
      // showmodalzeng: false,
      // showmodalbian: false,
      headerName: "系统设置",
      // Img1: Img0,
      // Img0: Img1,
      // current: ["mail"],
      // isLoading: false, //table 表格loadding
      // queryParam: {}, //查询参数
      // columns: [
      //   {
      //     title: "序号",
      //     dataIndex: "printer_id",
      //   },
      //   {
      //     title: "操作",
      //     dataIndex: "action",
      //     scopedSlots: { customRender: "action" },
      //   },
      //    {
      //     title: "打印机名称",
      //     dataIndex: "custom_name",
      //     // scopedSlots: { customRender: 'printer_name' }
      //   },
      //   {
      //     title: "打印机",
      //     dataIndex: "printer_name",
      //     // scopedSlots: { customRender: 'printer_name' }
      //   },
      //   {
      //     title: "打印机类型",
      //     dataIndex: "printer_type",
      //   },

      //   {
      //     title: "纸张类型",
      //     dataIndex: "width",
      //   },
      //   {
      //     title: "是否可用",
      //     scopedSlots: { customRender: "use" },
      //   },
      // ],
      // loadData: [], //表格数据
      // everyListData: "", //点击编辑每行table表格数据
      sideMenu: [
        { key: 0, value: "绑定打印机" },
        { key: 1, value: "通讯秤设置" },
        { key: 2, value: "进入后台" },
      ],
      // showContentId: 0, // 主要内容切换
      // siderName: "绑定打印机", //sider切换内容
      // showDelete: false, //是否展示删除modal
      // printer_id: 0, //选中打印机id
      // total:'',//总数据数
      // currents: 1,//当前页
    };
  },

  components: {
    touTop,
    // AddForm,
    // EditForm,
    sider,
    // merchant,
    // breadCrumb,
  },
  created() {
    // console.log('1111111111111')
  },
  mounted() {
    // this.tableList();
  },
  updated() {
    // console.log(JSON.stringify(this.loadData))
  },
  computed: {},
  watch: {},
  methods: {
    // handleTableChange(e){
    //   this.currents = e.current;
    //   this.tableList()
    // },
    // handleCancel() {
    //   this.showDelete = false;
    // },
    // changemodal(state) {
    //   this.showmodalzeng = state;
    // },
    getKey(key) {
      this.showContentId = key;
      this.siderName = this.sideMenu[key].value;
       if(key===0){
         this.$router.push({path: '/install/index',query: {key: key}})
      };
      if(key===1){
         this.$router.push({path: '/install/weight',query: {key: key}})
      };
      if(key===2){
      // window.location.href= 'https://test.yt91.cn/store/#/passport/login';
       this.jumpStore()
        //  this.$router.push({path: '/mis/record',query: {key: key}})
      };
    },
    // changeModalBian(state) {
    //   this.showmodalbian = state;
    // },
    // handleAdd() {
    //   this.showmodalzeng = true;
    // },
    // judgeType(number) {
    //   //判断打印机的类型
    //   if (number === "2") {
    //     return "云打印机";
    //   } else if (number === "1") {
    //     return "网络打印机";
    //   } else if (number === "0") {
    //     return "本地打印机";
    //   }
    // },
    // tableList() {
    //   //表格数据请求
    //   const _this = this;
    //   list({page:this.currents}).then((response) => {
    //     const oldList = response.data.data.list.data;
    //     _this.total=response.data.data.list.total && response.data.data.list.total;
    //     let newList = [];
    //     for (let i = 0; i < oldList.length; i++) {
    //       // console.log(JSON.stringify(oldList[i]));
    //       const printer_type = this.judgeType(oldList[i].printer_type);
    //       const voucherlist = this.voucherList(oldList[i].voucher_list);
    //       const newObject = {
    //         custom_name:oldList[i].custom_name,
    //         only_cut:oldList[i].only_cut,
    //         printer_id: oldList[i].printer_id,
    //         printer_name: oldList[i].printer_name,
    //         printer_type: printer_type,
    //         status: oldList[i].status,
    //         width: `${oldList[i].width}mm`,
    //         print_times: oldList[i].print_times,

    //         printer_category: oldList[i].printer_category,
    //         voucher_list: voucherlist,
    //         printer_config: {
    //           address: oldList[i].printer_config.address
    //             ? oldList[i].printer_config.address
    //             : "",
    //           prot: oldList[i].printer_config.prot
    //             ? oldList[i].printer_config.prot
    //             : "",
    //           USER: oldList[i].printer_config.USER
    //             ? oldList[i].printer_config.USER
    //             : "",
    //           UKEY: oldList[i].printer_config.UKEY
    //             ? oldList[i].printer_config.UKEY
    //             : "",
    //           SN: oldList[i].printer_config.SN
    //             ? oldList[i].printer_config.SN
    //             : "",
    //           brand: oldList[i].printer_config.brand,
    //         },
    //       };

    //       newList.push(newObject);
    //     }
    //     // const ss={
    //     //   total:response.data.list.total,
    //     //   per_page:response.data.list.per_page,
    //     //   current_page:response.data.list.current_page,
    //     //   last_page:response.data.list.last_page,
    //     //   data:newList,
    //     // }
    //     _this.loadData = newList;
    //     //console.log(JSON.stringify( _this.loadData))
    //   });
    // },
    // voucherList(data) {
    //   const arr = [];
    //   for (let i = 0; i < data.length; i++) {
    //     arr.push(data[i].receipt_id);
    //   }
    //   return arr;
    // },
    // handleRefresh() {
    //   //强制刷
    //   this.tableList();
    // },
    // handleEdit(item) {
    //   //点击表格编辑
    //   this.showmodalbian = true;
    //   this.everyListData = item;
    // },
    // handleOk(item) {
    //   const app = this;
    //   deleted({ printerId: app.printer_id })
    //     .then((result) => {
    //       console.log(result.status);
    //       if (result.status === 200) {
    //         app.showDelete = false;
    //         app.$message.success("更新成功", 1.5);
    //         app.handleRefresh();
    //       }

    //       // app.handleRefresh()
    //     })
    //     .catch((result) => {
    //       return false;
    //     });
    // },
    // handleDelete(item) {
    //   this.showDelete = true;
    //   this.printer_id = item.printer_id;
    
    // },
    jumpStore(){
       toStore()
        .then((result) => {
          console.log(result.status);
          if (result.status === 200) {
             const token=result.data.data.token && result.data.data.token;
            // localStorage.setItem('ACCESS_TOKEN', token);
           //localStorage.setItem('ACCESS_TOKEN', token)
            //window.open('http://localhost:8000/#/passport/login '+encodeURI(token),'')
             window.open(process.env.VUE_APP_HOU+encodeURI(token),'')
            
           // window.location.href= 'https://test.yt91.cn/store/#/passport/login';
          } 
        })
        .catch((result) => {
          return false;
        });
    }
  },
};
</script>
<style lang="less" scoped>
.lefttotal {
  height: 95.4vh;
  width: 100%;
  .shezhi {
    width: 100%;
    display: flex;
    height: 95.4vh;
    .left {
      width: 17vw;
      background: rgba(46, 62, 78, 1);
    }
    // .right {
    //   width: 83vw;
    //   height: 95.4vh;
    //   background: rgba(248, 248, 255, 1);
    //   img {
    //     width: 1.5vw;
    //     height: 1.5vw;
    //   }
    //   /deep/.ant-table-wrapper {
    //     margin-top: 0.05rem;
    //     .ant-table-tbody > tr > td {
    //       padding: 10px;
    //     }
    //   }
    //   /deep/.ant-btn-primary {
    //     background-color: rgba(255, 103, 0, 1);
    //     border-color: rgba(255, 103, 0, 1);
    //   }
    //   /deep/.ant-btn {
    //     width: 6vw;
    //     height: 4vh;
    //     font-size: 1.8vh;
    //     line-height: 4vh;
    //   }
    //   /deep/.ant-table-thead > tr > th {
    //     font-weight: 600;
    //     font-size: 1.8vh;
    //     height: 5vh;
    //   }
    //   /deep/.ant-table-wrapper .ant-table-tbody > tr > td {
    //     height: 3vh;
    //     line-height: 3vh;
    //     font-size: 1.8vh;
    //   }
    // }
  }
}
// /deep/.ant-btn-primary {
//   border-color: rgba(255, 103, 0, 1) !important;
//   background-color: rgba(255, 103, 0, 1) !important;
// }
// /deep/.ant-modal-title {
//   font-size: 2vh;
//   font-weight: 600;
// }
// /deep/.ant-modal-body {
//   font-size: 1.8vh;
// }
// /deep/.ant-card {
//   background-color:rgba(248, 248, 255, 1) ;
// }
// /deep/td{
//   // padding:1.5vh !important;
//   background: white !important;
// }  
// /deep/th{
//   background: rgba(248, 248, 255, 1) !important;
// }
// /deep/th:nth-child(2){
//   width:12vw !important;
// }
// /deep/th:nth-child(1){
//   width:7vw !important;
// }
// /deep/th:nth-child(3){
//   width:10vw !important;
// }
// /deep/th:nth-child(4){
//   width:10vw !important;
// }
// /deep/th:nth-child(5){
//   width:10vw !important;
// }
// /deep/th:nth-child(6){
//   width:7vw !important;
// }
// /deep/th:nth-child(7){
//   width:7vw !important;
// }
// /deep/.ant-table-thead > tr > th, .ant-table-tbody > tr > td{
//   padding:10px;
// }
// /deep/.table-operator{
//   margin-bottom: 2vh;;
// }
</style>
